import React, { useState } from "react";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { NavDetails as details } from "./NavDetails";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import "./Navbar.css";

export default function Navbar() {
  const token = sessionStorage.getItem("token");

  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const handleLogout = () => {
    sessionStorage.clear();
    window.location = "/";
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#8A8E84" }}>
        <div className="navbar">
          {token !== null ? (
            <Link to="#" className="menu-bars">
              <BsIcons.BsChevronBarRight onClick={showSidebar} />
            </Link>
          ) : (
            " "
          )}

          <Link to="/trip" className="logo menu-bars ">
            <p>Tag8 RTLS</p>
          </Link>
        </div>

        {token !== null ? (
          <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
            <ul className="nav-menu-items" onClick={showSidebar}>
              <li className="navbar-toggle">
                <Link to="#" className="menu-bars">
                  <BsIcons.BsChevronBarLeft />
                </Link>
              </li>

              {/* nav-text */}
              {details.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      <span className="nav-text-icon">{item.icon}</span>

                      <span className="nav-text-title">{item.title}</span>
                    </Link>
                  </li>
                );
              })}

              <div className="logout-container">
                <button className="logout-button" onClick={handleLogout}>
                  <FaIcons.FaSignOutAlt />
                  Logout
                </button>
              </div>
            </ul>
          </nav>
        ) : (
          " "
        )}
      </IconContext.Provider>
    </>
  );
}
