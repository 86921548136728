import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import Modal from "react-modal";
import axios from "axios";
import jwt_decode from "jwt-decode";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import * as IoIcons from "react-icons/io";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import "./UserManagement.css";
import {
  REACT_APP_NODE_ENV,
  REACT_APP_USER_DEV,
  REACT_APP_USER_LIVE,
  REACT_APP_USER_UPDATE_DEV,
  REACT_APP_USER_UPDATE_LIVE,
  REACT_APP_USER_UPLOAD_DEV,
  REACT_APP_USER_UPLOAD_LIVE,
} from "../../api/apiRoutes";

// when in dev env
// const baseURL = `http://localhost:3065/api/getAllUsers`;
// const uploadUserURL = "http://localhost:3065/api/userRegister";
// const updateUserURL = "http://localhost:3065/api/updateUser";

// when in live/build
// const baseURL = `/api/getAllUsers`;
// const uploadUserURL = "/api/userRegister";
// const updateUserURL = "/api/updateUser";

const baseURL =
  REACT_APP_NODE_ENV === "DEV" ? REACT_APP_USER_DEV : REACT_APP_USER_LIVE;

const uploadUserURL =
  REACT_APP_NODE_ENV === "DEV"
    ? REACT_APP_USER_UPLOAD_DEV
    : REACT_APP_USER_UPLOAD_LIVE;

const updateUserURL =
  REACT_APP_NODE_ENV === "DEV"
    ? REACT_APP_USER_UPDATE_DEV
    : REACT_APP_USER_UPDATE_LIVE;

Modal.setAppElement("#root");

export default function UserManagement() {
  const [modifyModalIsOpen, setModifyModalIsOpen] = useState(false);
  const [userModalIsOpen, setUserModalIsOpen] = useState(false);
  const [tableData, setTableData] = useState(null);

  // for user register
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cc, setCc] = useState("");
  const [phone, setPhone] = useState("");
  const [radio, setRadio] = useState();

  // for modified user
  const [modifiedEmail, setModifiedEmail] = useState("");
  const [modifiedStatus, setModifiedStatus] = useState("");
  const [modifiedCc, setModifiedCc] = useState("");
  const [modifiedPhone, setModifiedPhone] = useState("");
  const [modifiedRole, setModifiedRole] = useState();

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const initialValue = {
    email: "",
    phonecountrycode: "",
    contactg: "",
    status: "",
    role: "",
  };

  const [formData, setFormData] = useState(initialValue);

  const isNumber = (value) => {
    if (!isNaN(value)) {
      return value;
    } else {
      alert("Country code and Phone number should be integers");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = {
      email: email,
      password: password,
      cc: isNumber(cc),
      phone: isNumber(phone),
    };

    // uploadUserData(postData);
  };

  const token = sessionStorage.getItem("token");
  var decoded = jwt_decode(token);
  const checkEmail = decoded.email;

  const uploadUserData = (data) => {
    let formData = new FormData();
    var token = sessionStorage.getItem("token");
    var decoded = jwt_decode(token);

    // formData.append("id", decoded.id);
    formData.append("companyId", decoded.companyId);

    axios({
      url: uploadUserURL,
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
      },
      data: {
        role: radio,
        email: email,
        phone: phone,
        phonecountrycode: cc,
        password: password,
      },
    })
      .then((response) => {
        if (response.request.status === 200) {
          alert("User Registered");
          window.location = window.location.pathname;
        } else {
          alert("Please try again");
        }
      })
      .catch((e) => {
        console.error(e.message);

        alert(e);
      });
  };

  const getData = () => {
    var token = sessionStorage.getItem("token");
    var { id, companyId } = jwt_decode(token);

    const formData = {
      id: id,
      companyId: companyId,
    };

    axios({
      url: baseURL,
      method: "POST",
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
      },
      data: formData,
    })
      .then((response) => {
        const result = response.data.data.reverse();
        const userArr = [];

        result.map((item) => {
          return userArr.push(item.email);
        });

        const newUserArr = userArr.filter((value, index, arr) => {
          return arr.indexOf(value) === index;
        });

        // sessionStorage.setItem("users", JSON.stringify(newUserArr));
        setTableData(result);
      })
      .catch((e) => console.error(e.message));
  };

  useEffect(() => {
    getData();
  }, []);

  const columnDefs = [
    { headerName: "User Email", field: "email" },
    {
      headerName: "Contact no.",
      field: "phone",
      cellRendererFramework: (params) => {
        const { phonecountrycode: cc, phone } = params.data;

        return (
          <>
            {cc !== null ? (
              <p>
                (+{cc}){phone}
              </p>
            ) : (
              <p>{phone}</p>
            )}
          </>
        );
      },
    },
    {
      headerName: "User Status",
      field: "status",
      cellRendererFramework: (params) => {
        return params.data.status === "active" ? (
          <p style={{ color: "blue", fontStyle: "bold" }}>
            {" "}
            {params.data.status}
          </p>
        ) : (
          <p style={{ color: "red", fontStyle: "bold" }}>
            {" "}
            {params.data.status}
          </p>
        );
      },
    },
    {
      headerName: "Created on",
      field: "createdOn",
      cellRendererFramework: (params) => {
        const date = params.data.createdOn.split(" ");

        return (
          <button className="userCreatedBtn">
            {date[0]} <br /> {date[1]} {date[2]}
          </button>
        );
      },
    },
    {
      headerName: "Updated on",
      field: "updatedOn",
      cellRendererFramework: (params) => {
        const date = params.data.updatedOn.split(" ");

        return (
          <button className="userUpdatedBtn">
            {date[0]} <br /> {date[1]} {date[2]}
          </button>
        );
      },
    },
    // { headerName: "Country Code", field: "phonecountrycode" },
    {
      headerName: "Role",
      field: "role",
      cellRendererFramework: (params) => {
        return params.data.role === "admin" ? (
          <p style={{ color: "orange" }}>{params.data.role}</p>
        ) : (
          <p style={{ color: "black" }}>{params.data.role}</p>
        );
      },
    },
    {
      headerName: "Action",
      field: "action",
      cellRendererFramework: (params) => (
        <>
          <button
            className="modal-edit btn"
            onClick={() => {
              setModifyModalIsOpen(true);
              handleUserModify(params.data);
            }}
          >
            <MdIcons.MdEdit />
          </button>
        </>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    flex: 1,
  };

  const handleUserModify = (olddata) => {
    setFormData(olddata);
  };

  const handleModifySubmit = (e) => {
    e.preventDefault();
    uploadUserUpdateData();
  };

  const uploadUserUpdateData = () => {
    const modifyFormData = {
      email: modifiedEmail,
      phonecountrycode: modifiedCc,
      phone: modifiedPhone,
      status: modifiedStatus,
      role: modifiedRole,
    };

    axios({
      url: updateUserURL,
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
      },
      data: modifyFormData,
    })
      .then((response) => {
        console.info(response);
      })
      .catch((e) => console.error(e.message));
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onFilterTextChange = (e) => {
    gridApi.setQuickFilter(e.target.value);
  };

  const handleModalClose = (e) => {
    e.preventDefault();

    setEmail(" ");
    setPassword(null);
    setCc(" ");
    setPhone(" ");

    setUserModalIsOpen(false);
  };

  const handleUpdateUserModalClose = (e) => {
    e.preventDefault();

    setModifyModalIsOpen(false);
  };

  return (
    <>
      {checkEmail === "amore@tag8.in" ||
      checkEmail === "rohit.taneja@tag8.in" ||
      checkEmail === "mithun.dhuri@tag8.in" ? (
        <button className="addBtn" onClick={() => setUserModalIsOpen(true)}>
          Add User
        </button>
      ) : (
        ""
      )}

      <Modal
        className="register_modal"
        isOpen={userModalIsOpen}
        onRequestClose={() => setUserModalIsOpen(false)}
      >
        <form
          action="post"
          className="userFormContainer"
          onSubmit={handleSubmit}
        >
          <h2
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: ".5rem",
            }}
          >
            <RiIcons.RiUserAddFill /> Register User
          </h2>

          <label htmlFor="email">Email</label>
          <input
            required
            className="modalInput"
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <label htmlFor="password">Password</label>
          <input
            required
            className="modalInput"
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <label>Country code and Phone number</label>

            <span
              style={{
                display: "flex",
                gap: ".2rem",
              }}
            >
              <input
                required
                className="cc-modalInput"
                type="text"
                name="cc"
                id="cc"
                value={cc}
                onChange={(e) => setCc(e.target.value)}
              />

              <input
                required
                className="modalInput"
                type="text"
                name="phone"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </span>
          </div>

          {/* <label className="form-control">
            <input
              type="radio"
              value="user"
              onChange={(e) => setRadio(e.target.value)}
            />
            User
          </label> */}

          {/* <label className="form-control">
            <input
              type="radio"
              value="admin"
              onChange={(e) => setRadio(e.target.value)}
            />
            Admin
          </label> */}

          <span style={{ display: "flex", justifyContent: "space-evenly" }}>
            <input type="submit" value="Register" className="registerBtn" />

            <button
              onClick={(e) => handleModalClose(e)}
              className="modal-closeBtn"
            >
              Cancel
            </button>
          </span>
        </form>
      </Modal>

      <input
        type="text"
        id="user-filter-box"
        placeholder="Search"
        onChange={(e) => onFilterTextChange(e)}
      />

      <div className="user-mgmnt-container">
        <div
          className="ag-theme-alpine"
          style={{
            height: "100%",
            width: "99.9%",
            paddingLeft: "1px",
            borderLeft: "1px solid #babfc7",
            borderRight: "1px solid #babfc7",
          }}
        >
          <AgGridReact
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            rowData={tableData}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={25}
            domLayout="autoHeight"
            isInverted={true}
            overlayLoadingTemplate={
              '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
            }
            overlayNoRowsTemplate={
              '<span className="ag-overlay-loading-center">No data found to display.</span>'
            }
          ></AgGridReact>

          <Modal
            className="modify_modal"
            isOpen={modifyModalIsOpen}
            onRequestClose={() => setModifyModalIsOpen(false)}
          >
            <form className="userFormContainer" onSubmit={handleModifySubmit}>
              <h2>
                <IoIcons.IoMdAlert /> Update User
              </h2>

              <label htmlFor="email">User Email</label>
              <input
                className="modalInput"
                type="email"
                name="email"
                value={formData.email}
                onChange={(e) => setModifiedEmail(e.target.value)}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <label>Country code and Phone number</label>

                <span
                  style={{
                    display: "flex",
                    gap: ".2rem",
                  }}
                >
                  <input
                    className="cc-modalInput"
                    type="text"
                    name="cc"
                    value={formData.phonecountrycode}
                    onChange={(e) => setModifiedCc(e.target.value)}
                  />

                  <input
                    className="modalInput"
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={(e) => setModifiedPhone(e.target.value)}
                  />
                </span>
              </div>

              <label htmlFor="status">User Status</label>
              <input
                className="modalInput"
                type="text"
                name="status"
                value={formData.status}
                onChange={(e) => setModifiedStatus(e.target.value)}
              />

              <label htmlFor="status">User Role</label>
              <input
                className="modalInput"
                type="text"
                name="role"
                value={formData.role}
                onChange={(e) => setModifiedRole(e.target.value)}
              />

              <span style={{ display: "flex", justifyContent: "space-evenly" }}>
                <input type="submit" value="Update" className="updateBtn" />

                <button
                  onClick={(e) => handleUpdateUserModalClose(e)}
                  className="modal-closeBtn"
                >
                  Cancel
                </button>
              </span>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
}
