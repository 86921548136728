import React, { useState } from "react";
import axios from "axios";

import "./Login.css";
import {
  REACT_APP_NODE_ENV,
  REACT_APP_LOGIN_DEV,
  REACT_APP_LOGIN_LIVE,
  REACT_APP_TXN_DEV,
  REACT_APP_TXN_LIVE,
} from "../../api/apiRoutes";

// when in dev env
// const baseURL = "http://localhost:3065/api/login/";
// const padlockBaseURL = "http://localhost:3065/api/transaction/getTransaction";

// when making live/build
// const baseURL = "/api/login/";
// const padlockBaseURL = "/api/transaction/getTransaction";

const baseURL =
  REACT_APP_NODE_ENV === "DEV" ? REACT_APP_LOGIN_DEV : REACT_APP_LOGIN_LIVE;

const padlockBaseURL =
  REACT_APP_NODE_ENV === "DEV" ? REACT_APP_TXN_DEV : REACT_APP_TXN_LIVE;

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    pushData();
  };

  const pushData = () => {
    axios
      .post(baseURL, {
        email: email,
        password: pass,
      })
      .then(function (response) {
        console.log(response);
        var responseData = Object.keys(response.data);

        if (response.status === 200) {
          // alert(response.data.token);
          const sessionToken = response.data.token;

          sessionStorage.setItem("token", sessionToken);

          window.location = "/trip";

          getReportData();
        } else {
          response.data.msg
            ? alert(response.data.msg)
            : alert("Failure login");
        }
      })
      .catch((error) => {
        console.log(error)});
  };

  const getReportData = () => {
    axios({
      url: padlockBaseURL,
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
      },
    }).then((resp) => {
      const result = resp.data.data.reverse();

      const padlockArr = [];
      const statusArr = [];

      result.map((item) => {
        return padlockArr.push(item.lockId);
      });

      result.map((item) => {
        return statusArr.push(item.transationType);
      });

      const newPadlockArr = padlockArr.filter((value, index, array) => {
        return array.indexOf(value) === index;
      });

      const newStatusArr = statusArr.filter((value, index, array) => {
        return array.indexOf(value) === index;
      });

      localStorage.setItem("padlock", JSON.stringify(newPadlockArr));
      localStorage.setItem("status", JSON.stringify(newStatusArr));
    });
  };

  return (
    <>
      <div className="login-body-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <input
            className="email-input"
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <input
            className="password-input"
            placeholder="password"
            type="password"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            required
          />

          <button className="login-btn" onSubmit={handleSubmit}>
            Login
          </button>
        </form>
      </div>
    </>
  );
}
