import React from "react";
import TripMaster from "../components/TripMaster/TripMaster";

export default function Master() {
  return (
    <div className="master">
      <TripMaster />
      {/* <h1>this is trip master page</h1> */}
    </div>
  );
}
