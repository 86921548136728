import React from "react";
import Dashboard from "../components/Dashboard/Dashboard";

export default function DashboardPage() {
  return (
    <>
      <Dashboard />
      {/* <h1>this is Dashboard page</h1> */}
    </>
  );
}
