import React from "react";
import "./TemplateBtn.css";
import { CSVLink } from "react-csv";
import * as FaIcons from "react-icons/fa";

export const TemplateBtn = (props) => {
  const data = [props.data];

  return (
    <>
      <CSVLink className="csvTemplateBtn" data={data} filename={"Template.csv"}>
        <FaIcons.FaFileCsv />
      </CSVLink>
    </>
  );
};
