import React, { useState, useEffect, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import Modal from "react-modal";
import axios from "axios";
import jwt_decode from "jwt-decode";
import ToggleButton from "react-toggle-button";
import * as AiIcon from "react-icons/ai";
import * as FaIcon from "react-icons/fa";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";

import "./TripMaster.css";
import defaultColDef from "../Utility/defaultColDef";
import InfyLoading from "../../assests/Infinity-200px.svg";
import { Datepicker } from "../Utility/Datepicker";
import {
  REACT_APP_NODE_ENV,
  REACT_APP_TXN_DEV,
  REACT_APP_TXN_LIVE,
  REACT_APP_SEARCH_DEV,
  REACT_APP_SEARCH_LIVE,
  REACT_APP_PADLOCK_DEV,
  REACT_APP_PADLOCK_LIVE,
} from "../../api/apiRoutes";

const baseURL =
  REACT_APP_NODE_ENV === "DEV" ? REACT_APP_TXN_DEV : REACT_APP_TXN_LIVE;

const searchURL =
  REACT_APP_NODE_ENV === "DEV" ? REACT_APP_SEARCH_DEV : REACT_APP_SEARCH_LIVE;

const padlockBaseURL =
  REACT_APP_NODE_ENV === "DEV" ? REACT_APP_PADLOCK_DEV : REACT_APP_PADLOCK_LIVE;

Modal.setAppElement("#root");

const TripMaster = () => {
  var token = sessionStorage.getItem("token");
  var { id, companyId } = jwt_decode(token);
  const paramsArr = [];

  const padlockFormData = {
    id: id,
    companyId: companyId,
  };

  const [tableData, setTableData] = useState(null);
  const [searchTableData, setSearchTableData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [mapModalIsOpen, setMapModalIsOpen] = useState(false);
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState([]);
  const [pageEntries, setPageEntries] = useState(25);
  const [search, setSearch] = useState("");
  const [infyLoading, setInfyLoading] = useState(false);
  const [entries, setEntries] = useState();
  const [toggle, setToggle] = useState(false);

  const gridRef = useRef();

  const getData = () => {
    setInfyLoading(true);

    var token = sessionStorage.getItem("token");
    var decoded = jwt_decode(token);

    const formData = {
      companyId: decoded.companyId,
    };

    axios({
      url: `${baseURL}?page=${pageNumber}&entries=${pageEntries}`,
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
      },
      data: formData,
    })
      .then((resp) => {
        const result = resp.data.data;
        const noOfPages = resp.data.totalPages;

        sessionStorage.setItem("data", JSON.stringify(result));

        setTableData(result);
        setEntries(result.totalCount);
        setNumberOfPages(noOfPages);
        setInfyLoading(false);
      })
      .catch((e) => console.error(e));
  };

  const searchData = async () => {
    setInfyLoading(true);

    var token = sessionStorage.getItem("token");
    var decoded = jwt_decode(token);

    const formData = {
      companyId: decoded.companyId,
    };

    axios({
      url: `${searchURL}?key=${search}`,
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
      },
      data: formData,
    })
      .then((resp) => {
        const result = resp.data.data;

        setSearchTableData(result);
        setInfyLoading(false);
      })
      .catch((e) => console.error(e));
  };

  const getPadlockIdsAndStoreSessionStorage = () => {
    axios({
      url: padlockBaseURL,
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
      },
      data: padlockFormData,
    })
      .then((response) => {
        const results = response.data.data.reverse();
        const padLockIds = [];

        results.map((result) => {
          padLockIds.push(result.lockName);
        });

        sessionStorage.setItem("padLockIds", JSON.stringify(padLockIds));
      })
      .catch((e) => console.error(e.message));
  };

  useEffect(() => {
    getData();
  }, [pageNumber, pageEntries]);

  useEffect(() => {
    getPadlockIdsAndStoreSessionStorage();
  }, []);

  const checkLocation = (location) => {
    if (location && location.length > 1 && location !== " ") return location;

    return "Address Not Available";
  };

  const checkLocationBtn = (location) => {
    if (location && location.length > 1 && location !== " ") return false;

    return true;
  };

  function convertToIST(dateString) {
    // Create a new Date object from the input (assumes input is in ISO string format or valid Date string)
    const localDate = new Date(dateString);
  
    // Get the UTC time in milliseconds
    const utcTime = localDate.getTime() + (localDate.getTimezoneOffset() * 60000);
  
    // IST is UTC + 5 hours 30 minutes, so add this offset (in milliseconds)
    const istOffset = 5.5 * 60 * 60 * 1000; // 5 hours 30 minutes in milliseconds
    const istTime = new Date(utcTime + istOffset);
  
    // Format the IST time to YYYY-MM-DD HH:mm:ss
    const year = istTime.getFullYear();
    const month = String(istTime.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(istTime.getDate()).padStart(2, '0');
    const hours = String(istTime.getHours()).padStart(2, '0');
    const minutes = String(istTime.getMinutes()).padStart(2, '0');
    const seconds = String(istTime.getSeconds()).padStart(2, '0');
  
    // Return the formatted IST time
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const returnDwellTime = (hrs, mins, secs, err) => {
    if (err === "No Error") {
      return `${hrs}:${mins}:${secs}`;
    }

    return err;
  };

  const columnDefs = [
    {
      headerName: "User email",
      field: "userId",
      width: 250,
      // getQuickFilterText: (params) => params.value.name,
    },
    // {
    //   headerName: "Vehicle No.",
    //   field: "vehicalId",
    //   width: 130,
    // },
    {
      headerName: "Padlock ID",
      field: "lockId",
      width: 110,
    },
    {
      headerName: "Txn status",
      field: "transationType",
      width: 110,
      cellRendererFramework: (params) => {
        const { transationType } = params.data;

        return transationType === "unlock" ? (
          <p className="txnColumnInfo txnColumnInfoOpen">
            {transationType.toUpperCase()} <AiIcon.AiFillUnlock />
          </p>
        ) : (
          <p className="txnColumnInfo txnColumnInfoClose">
            {transationType.toUpperCase()} <AiIcon.AiFillLock />
          </p>
        );
      },
    },
    {
      headerName: "GPS Location",
      field: "latitude",
      width: 120,
      cellRendererFramework: (params) => {
        const { latitude, longitude } = params.data;

        const mapsLatitude = parseFloat(latitude);
        const mapsLongitude = parseFloat(longitude);

        return (
          <>
            <button
              className="mapsBtn"
              onClick={() => {
                setMapModalIsOpen(true);
                setLatitude(mapsLatitude);
                setLongitude(mapsLongitude);
              }}
              disabled={latitude === "0" && longitude === "0"}
            >
              {latitude === "0" ? "N/A" : latitude}
              <br />
              {longitude === "0" ? "N/A" : longitude}
            </button>
          </>
        );
      },
    },
    {
      headerName: "Location",
      field: "location",
      width: 230,
      cellRendererFramework: (params) => {
        const { location } = params.data;
        return (
          <>
            <button
              className="locationBtn"
              onClick={() => {
                setAddressModalIsOpen(true);
                setAddress(location);
              }}
              disabled={checkLocationBtn(location)}
            >
              {checkLocation(location)}
            </button>
          </>
        );
      },
    },
    // {
    //   headerName: "Dwell Time (hr:mm:ss)",
    //   field: "dwellTime",
    //   width: 196,
    //   cellRendererFramework: (params) => {
    //     const { hours, minutes, seconds, error } = params.data;

    //     return (
    //       <button
    //         className="dwellTimeBtn"
    //         disabled={error === "No Error" ? false : true}
    //       >
    //         {returnDwellTime(hours, minutes, seconds, error)}
    //       </button>
    //     );
    //   },
    // },
    {
      headerName: "Lock Operation Time",
      field: "uploadtime",
      width: 160,
      cellRendererFramework: (params) => {
        // const date = params.data.uploadtime.split(" ");
        const date = convertToIST(params.data.uploadtime);
        return (
          <button className="timeBtn">
            {date}
            {/* {date[0]} <br /> {date[1]} {date[2]} */}
          </button>
        );
      },
    },
    {
      headerName: "App Sync Time",
      field: "appSyncTime",
      width: 130,
      cellRendererFramework: (params) => {
        // const date = params.data.appSyncTime.split(" ");
        const date = convertToIST(params.data.appSyncTime);
        return (
          <button className="timeBtn">
            {date}
            {/* {date[0]} <br /> {date[1]} {date[2]} */}
          </button>
        );
      },
    },
  ];

  const onTextChange = (e) => {
    toggle ? setSearch(e.target.value) : gridApi.setQuickFilter(e.target.value);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const center = {
    lat: latitude,
    lng: longitude,
  };

  const getRowId = useCallback((params) => {
    return params.data.id;
  });

  const options = [10, 25, 50, 75, 100, 150, 200, 250];

  const fetchSearchData = (e) => {
    e.preventDefault();

    searchData();
  };

  const onSelectChange = (e) => {
    setPageEntries(e.target.value);
  };

  return (
    <>
      <div className="trip-picker-container">
        <Datepicker />
      </div>

      <div className="transaction-utility">
        <div className="global-search-container">
          <ToggleButton
            inactiveLabel={<FaIcon.FaSearch />}
            activeLabel={<FaIcon.FaGlobe />}
            value={toggle}
            onToggle={(value) => {
              setToggle(!value);
            }}
          />

          <input
            type="search"
            id="trip-filter-box"
            placeholder="Search"
            // value={search}
            onChange={onTextChange}
          />

          {toggle ? (
            <button
              className="search-transactions"
              onClick={(e) => {
                fetchSearchData(e);
              }}
            >
              Fetch
            </button>
          ) : (
            <button className="search-transactions-hide" disabled={true}>
              Fetch
            </button>
          )}
        </div>

        <div className="page-info">
          {infyLoading ? (
            <img src={InfyLoading} alt="spinner" className="infyLoading" />
          ) : (
            ""
          )}

          <span>
            Showing
            <select
              className="page-number-select"
              value={pageEntries}
              onChange={(e) => onSelectChange(e)}
            >
              {options.map((option, idx) => (
                <option key={idx} value={option}>
                  {option}
                </option>
              ))}
            </select>
            entries
          </span>

          <button
            className="page-info-btn"
            onClick={() => setPageNumber(0)}
            disabled={pageNumber === 0}
          >
            <FaIcon.FaStepBackward />
          </button>

          <button
            className="page-info-btn"
            onClick={() => setPageNumber(pageNumber - 1)}
            disabled={pageNumber === 0}
          >
            <FaIcon.FaLessThan />
          </button>

          <p>
            Page {pageNumber + 1} of {numberOfPages}
          </p>

          <button
            className="page-info-btn"
            onClick={() => setPageNumber(pageNumber + 1)}
            disabled={pageNumber === numberOfPages - 1}
          >
            <FaIcon.FaGreaterThan />
          </button>

          <button
            className="page-info-btn"
            onClick={() => setPageNumber(numberOfPages - 1)}
            disabled={pageNumber === numberOfPages - 1}
          >
            <FaIcon.FaStepForward />
          </button>
        </div>
      </div>

      <div className="ag-theme-alpine ag-style">
        <AgGridReact
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          // rowData={tableData}
          rowData={toggle ? searchTableData : tableData}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          ref={gridRef}
          overlayLoadingTemplate={
            '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
          }
          overlayNoRowsTemplate={
            '<span className="ag-overlay-loading-center">No data found to display.</span>'
          }
          getRowId={getRowId}
          animateRows={true}
          enableCellChangeFlash={true}
        ></AgGridReact>
      </div>

      <Modal
        className="map-modal"
        centered
        isOpen={mapModalIsOpen}
        onRequestClose={() => setMapModalIsOpen(false)}
      >
        <span>
          <MapContainer center={center} zoom={14}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <Marker position={center}></Marker>
          </MapContainer>

          <button
            className="map-modal-btn map-modal-close-btn"
            onClick={() => setMapModalIsOpen(false)}
          >
            Close
          </button>
        </span>
      </Modal>

      <Modal
        className="address-modal"
        centered
        isOpen={addressModalIsOpen}
        onRequestClose={() => setAddressModalIsOpen(false)}
      >
        <span>
          <h3>Full Address</h3>

          <div className="location-container">
            {address.split(",").map((item, index) => {
              return <p key={index}>{item}</p>;
            })}
          </div>

          <button
            className="address-modal-close-btn"
            onClick={() => setAddressModalIsOpen(false)}
          >
            <AiIcon.AiOutlineClose />
          </button>
        </span>
      </Modal>
    </>
  );
};

export default TripMaster;
