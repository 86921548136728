import React from "react";
import Reports from "../components/Reports/Reports";

export default function ReportsPage() {
  return (
    <>
      <Reports />
      {/* <h1>this is reports page</h1> */}
    </>
  );
}
