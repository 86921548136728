import React from "react";
import "./InfoCards.css";
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import * as DiIcons from "react-icons/di";

const items = JSON.parse(sessionStorage.getItem("data")) || [];

const padlockId = items
  .map((item) => item.lockId)
  .filter((value, index, array) => {
    return array.indexOf(value) === index;
  });

const users = items
  .map((item) => item.userId)
  .filter((value, index, array) => {
    return array.indexOf(value) === index;
  });

const vehicles = items
  .map((item) => item.vehicalId)
  .filter((value, index, array) => {
    return array.indexOf(value) === index;
  });

export const InfoCard = () => {
  return (
    <>
      <div className="card">
        <span className="top ">
          <span className="locks-top-icon">
            <AiIcons.AiFillLock />
          </span>

          <p>Total Locks</p>
        </span>

        <h1>
          {padlockId.length > 0 &&
          padlockId.length !== null &&
          padlockId.length !== ""
            ? padlockId.length
            : "NA"}
        </h1>
      </div>

      <div className="card">
        <span className="top">
          <span className="users-top-icon">
            <HiIcons.HiUser />
          </span>

          <p> Total Users</p>
        </span>

        <h1>
          {users.length > 0 && users.length !== null && users.length !== ""
            ? users.length
            : "NA"}
        </h1>
      </div>

      <div className="card">
        <span className="top">
          <span className="vehicles-top-icon">
            <AiIcons.AiFillCar />
          </span>

          <p> Total Vehicles</p>
        </span>

        <h1>
          {vehicles.length > 0 &&
          vehicles.length !== null &&
          vehicles.length !== ""
            ? vehicles.length
            : "NA"}
        </h1>
      </div>

      <div className="card">
        <span className="top">
          <span className="trips-top-icon">
            <DiIcons.DiGoogleAnalytics />
          </span>

          <p> Total Transactions</p>
        </span>

        <h1>{items.length}</h1>
      </div>
    </>
  );
};
