import React, { useState } from "react";
import "react-dropdown/style.css";
import DatePicker from "react-datepicker";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { CSVLink } from "react-csv";
import Modal from "react-modal";
import Multiselect from "multiselect-react-dropdown";

import "./Reports.css";
import {
  REACT_APP_NODE_ENV,
  REACT_APP_REPORT_DEV,
  REACT_APP_REPORT_LIVE,
} from "../../api/apiRoutes";

Modal.setAppElement("#root");

// when in dev env
// const uploadBaseURL = "http://localhost:3065/api/report/getReport";

// when in live/build
// const uploadBaseURL = "/api/report/getReport";

const uploadBaseURL =
  REACT_APP_NODE_ENV === "DEV" ? REACT_APP_REPORT_DEV : REACT_APP_REPORT_LIVE;

export default function Reports() {
  const [padlock, setPadlock] = useState("");
  const [user, setUser] = useState("");
  const [status, setStatus] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState();
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [data, setData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  const items = JSON.parse(sessionStorage.getItem("data"));
  const padLockIds = JSON.parse(sessionStorage.getItem("padLockIds"));

  const padlockId = padLockIds.map((item, idx) => {
    return { padlock: item, id: idx };
  });

  const users = items
    .map((item) => item.userId)
    .filter((value, index, array) => {
      return array.indexOf(value) === index;
    })
    .map((item, index) => {
      return { users: item, id: index };
    });

  const vehicles = items
    .map((item) => item.vehicalId)
    .filter((value, index, array) => {
      return array.indexOf(value) === index;
    })
    .map((item, index) => {
      return { vehicles: item, id: index };
    });

  const options = {
    padlock: {
      label: "Filter by Padlock ID",
      json: padlockId,
    },
    user: {
      label: "Filter by Users",
      json: users,
    },
    status: {
      label: "Filter by Status",
    },
    vehicle: {
      label: "Filter by Vehicles",
      json: vehicles,
    },
  };

  const statusOptions = {
    options: [
      { status: "lock", id: 1 },
      { status: "unlock", id: 2 },
    ],
  };

  function formatFromDate(date = new Date()) {
    // if (date !== "") {
    let x = new Date(date);
    x.setHours(0, 0, 0, 0);
    return x;
    // }
  }

  function formatToDate(date) {
    let x = new Date(date);
    x.setHours(23, 59, 59, 0);
    return x;
  }

  const reportSubmit = (e) => {
    e.preventDefault();

    const pushReport = {
      lock:
        padlock.length > 0
          ? padlock.map((item) => item).map((ele) => ele.padlock)
          : "",
      push_user:
        user.length > 0 ? user.map((item) => item).map((ele) => ele.users) : "",
      push_status:
        status.length > 0
          ? status.map((item) => item).map((ele) => ele.status).length === 2
            ? ""
            : status.map((item) => item).map((ele) => ele.status)[0] || ""
          : "",
      push_vehicle:
        vehicle.length > 0
          ? vehicle.map((item) => item).map((ele) => ele.vehicles)
          : "",
      from: formatFromDate(selectedFromDate),
      to: formatToDate(selectedToDate),
    };

    setLoading(true);
    uploadReportData(pushReport);
  };

  const uploadReportData = (data) => {
    const token = sessionStorage.getItem("token");
    const decoded = jwtDecode(token);
    const postData = {
      id: decoded.companyId,
      from: data.from,
      to: data.to,
      status: data.push_status,
      padlockids: data.lock,
      users: data.push_user,
      vehicalNos: data.push_vehicle,
    };

    axios({
      url: uploadBaseURL,
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
      },
      data: postData,
    })
      .then((response) => {
        const results = response.data.data;

        setData(results);
        setLoading(false);
      })
      .catch((e) => console.error(e.message));
  };

  const headers = [
    { label: "User email", key: "userId" },
    { label: "Vehicle no", key: "vehicalId" },
    { label: "Padlock Id", key: "lockId" },
    { label: "Lock Operation Time", key: "uploadtime" },
    { label: "App Sync Time", key: "appSyncTime" },
    { label: "Status", key: "transationType" },
    { label: "Latitude", key: "longitude" },
    { label: "Longitude", key: "latitude" },
    { label: "Address", key: "location" },
  ];

  const csvReport = {
    filename: `${fileName}.csv`,
    headers: headers,
    data: data,
  };

  const handleInputChange = (e) => {
    setFileName(e.target.value);
  };

  const dropDownStyle = {
    searchBox: {
      padding: "0.5rem",
    },
    chips: {
      background: "#4676d7",
    },
    multiselectContainer: {
      width: "100%",
      fontFamily: "Lato",
    },
  };

  const reloadPage = () => {
    window.location = window.location.pathname;
  };

  return (
    <>
      <div className="reports-container">
        <div className="form-container">
          <div className="form-container-top">
            <div className="item">
              <label id="date-label">From</label>
              <DatePicker
                // id="datePicker"
                className="report-date-picker"
                selected={selectedFromDate}
                onChange={(date) => setSelectedFromDate(date)}
                dateFormat="dd/MM/yyyy"
                isClearable
              />
            </div>

            <div className="item">
              <label>To</label>
              <DatePicker
                // id="datePicker"
                className="report-date-picker"
                selected={selectedToDate}
                onChange={(date) => setSelectedToDate(date)}
                dateFormat="dd/MM/yyyyy"
                maxDate={new Date()}
                isClearable
              />
            </div>
          </div>

          <div className="form-container-mid">
            <div className="form-container-mid-left">
              <div className="item">
                <Multiselect
                  options={options.user.json}
                  displayValue="users"
                  // showCheckbox={true}
                  placeholder={options.user.label}
                  caseSensitiveSearch={true}
                  showArrow={true}
                  onSelect={(e) => {
                    setUser(e);
                  }}
                  style={dropDownStyle}
                  hidePlaceholder
                />
              </div>

              <div className="item">
                <Multiselect
                  options={options.vehicle.json}
                  displayValue="vehicles"
                  // showCheckbox={true}
                  placeholder={options.vehicle.label}
                  caseSensitiveSearch={true}
                  showArrow={true}
                  onSelect={(e) => setVehicle(e)}
                  style={dropDownStyle}
                  hidePlaceholder
                />
              </div>
            </div>

            <div className="form-container-mid-right">
              <div className="item">
                <Multiselect
                  options={options.padlock.json}
                  displayValue="padlock"
                  // showCheckbox={true}
                  placeholder={options.padlock.label}
                  caseSensitiveSearch={true}
                  showArrow={true}
                  onSelect={(e) => setPadlock(e)}
                  style={dropDownStyle}
                  hidePlaceholder
                />
              </div>

              <div className="item">
                <Multiselect
                  options={statusOptions.options}
                  displayValue="status"
                  // showCheckbox={true}
                  placeholder={options.status.label}
                  caseSensitiveSearch={true}
                  showArrow={true}
                  onSelect={(e) => setStatus(e)}
                  style={dropDownStyle}
                  hidePlaceholder
                />
              </div>
            </div>
          </div>

          <div className="form-container-down">
            <button
              className="reportBtn"
              onClick={(e) => {
                reportSubmit(e);
                setIsOpen(true);
              }}
            >
              Export to CSV
            </button>
          </div>
        </div>
      </div>

      <Modal
        className="report-modal"
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        {loading ? (
          <div className="csvDownloading">Kindly wait fetching data...</div>
        ) : (
          <form className="reportFormNaming">
            <input
              className="fileNameInput"
              type="text"
              name="save"
              value={fileName}
              placeholder="Save file as"
              onChange={handleInputChange}
            />

            <span>
              <CSVLink
                className="csvBtn"
                {...csvReport}
                onClick={() => {
                  setIsOpen(false);
                  reloadPage();
                }}
              >
                Save
              </CSVLink>

              <button
                className="cancelBtn"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                }}
              >
                Cancel
              </button>
            </span>
          </form>
        )}
      </Modal>
    </>
  );
}
