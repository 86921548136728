import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
} from "chart.js";
import "./PadLockChart.css";

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

export default function PadLockChart() {
  const transactions = JSON.parse(sessionStorage.getItem("data"));
  const totalLocks = transactions.map((data) => data.transationType);
  const lock = [];
  const unlock = [];

  totalLocks.map((item) =>
    item === "lock" ? lock.push(item) : unlock.push(item)
  );

  const data = {
    labels: ["Two Days Ago", "Yesterday", "Todays"],
    datasets: [
      {
        label: "",
        data: [totalLocks.length, lock.length, unlock.length],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.4,
      },
    ],
  };

  return (
    <>
      <div className="chart-wrapper">
        <Line data={data} />
      </div>
    </>
  );
}
