import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import "./VehicleManagement.css";
import { TemplateBtn } from "../Utility/TemplateBtn";
import {
  REACT_APP_NODE_ENV,
  REACT_APP_VEHICLE_DEV,
  REACT_APP_VEHICLE_LIVE,
  REACT_APP_VEHICLE_UPLOAD_DEV,
  REACT_APP_VEHICLE_UPLOAD_LIVE,
} from "../../api/apiRoutes";

// when in dev env
// const baseURL = "http://localhost:3065/api/lock/getVehicalLockMapping";
// const uploadURL = "http://localhost:3065/api/lock/uploadVehicalLockMapping";

// when in live/build
// const baseURL = "/api/lock/getVehicalLockMapping";
// const uploadURL = "/api/lock/uploadVehicalLockMapping";

const baseURL =
  REACT_APP_NODE_ENV === "DEV" ? REACT_APP_VEHICLE_DEV : REACT_APP_VEHICLE_LIVE;

const uploadURL =
  REACT_APP_NODE_ENV === "DEV"
    ? REACT_APP_VEHICLE_UPLOAD_DEV
    : REACT_APP_VEHICLE_UPLOAD_LIVE;

export default function VehicleManagement() {
  const [tableData, setTableData] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const getData = () => {
    var token = sessionStorage.getItem("token");
    var decoded = jwt_decode(token);

    const formData = {
      companyId: decoded.companyId,
    };

    axios({
      url: baseURL,
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
      },
      data: formData,
    })
      .then((response) => {
        const result = response.data.data.reverse();

        setTableData(result);
      })
      .catch((e) => console.error(e.message));
  };

  useEffect(() => {
    getData();
  }, []);

  const columnDefs = [
    { headerName: "Vehicle no.", field: "vehicalNo" },
    { headerName: "Lock ID", field: "lockId" },
    { headerName: "Created By", field: "userId" },
    {
      headerName: "Created On",
      field: "createdOn",
      cellRendererFramework: (params) => {
        const date = params.data.createdOn.split(" ");

        return (
          <button className="createdTimeBtn">
            {date[0]} <br /> {date[1]} {date[2]}
          </button>
        );
      },
    },
  ];

  const defaultColDef = {
    resizable: true,
    flex: 1,
  };

  const uploadFile = (url, file) => {
    let formData = new FormData();
    var token = sessionStorage.getItem("token");
    var decoded = jwt_decode(token);

    formData.append("csv", file);
    formData.append("id", decoded.id);
    formData.append("companyId", decoded.companyId);

    axios({
      url: uploadURL,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((response) => {
        if (response.request.statusText === "OK") {
          alert("File uploaded successfully");

          // this here relods the page
          window.location = window.location.pathname;
        } else {
          alert("Please try again");
        }
      })
      .catch((e) => {
        console.error(e.message);

        alert(e);
      });
  };

  const onChange = (e) => {
    let csvFile = e.target.files[0];
    // const reader = new FileReader();

    // if (csvFile.size > 0) {
    //   reader.onload = (evt) => {
    //     // Parse data
    //     const bstr = evt.target.result;
    //     const wb = XLSX.read(bstr, { type: "binary" });

    //     // Get first worksheet
    //     const wsname = wb.SheetNames[0];
    //     const ws = wb.Sheets[wsname];

    //     // Convert array of arrays
    //     const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });

    //     // processData(data);

    //     // processData(data) === true
    //     //   ? uploadFile(uploadURL, csvFile)
    //     //   : alert(
    //     //       "Kindly check the file you're uploading probably it contains duplicate data or the headers of the column are wrong"
    //     //     );
    //     uploadFile(uploadFile, csvFile);
    //   };
    //   // uploadFile(uploadURL, csvFile);
    //   reader.readAsBinaryString(csvFile);
    // } else {
    //   alert(`${csvFile.name} file size is ${csvFile.size} which is empty`);
    // }
    uploadFile(uploadFile, csvFile);
  };

  // const processData = (dataString) => {
  //   const dataStringLines = dataString.split(/\r\n|\n/);
  //   const headers = dataStringLines[0].split(
  //     /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
  //   );
  //   const list = [];

  //   for (let i = 1; i < dataStringLines.length; i++) {
  //     const row = dataStringLines[i].split(
  //       /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
  //     );

  //     if (headers && row.length === headers.length) {
  //       const obj = {};

  //       for (let j = 0; j < headers.length; j++) {
  //         let d = row[j];

  //         if (d.length > 0) {
  //           if (d[0] === '"') d = d.substring(1, d.length - 1);
  //           if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
  //         }

  //         if (headers[j]) obj[headers[j]] = d;
  //       }

  //       // remove the blank rows
  //       if (Object.values(obj).filter((x) => x).length > 0) list.push(obj);
  //     }
  //   }

  //   const columns = headers.map((c) => ({
  //     name: c,
  //   }));

  //   const returnObj = {
  //     rList: list,
  //     header1: columns[0].name,
  //     header2: columns[1].name,
  //   };

  //   if (
  //     checkHeaders(returnObj.header1, returnObj.header2) === true &&
  //     checkData(returnObj.rList) === true
  //   ) {
  //     return true;
  //   } else {
  //     // console.error(
  //     //   "Kindly check the file you're uploading probably it contains duplicate data or the headers of the column are wrong"
  //     // );
  //     // alert("not good");
  //     return false;
  //   }
  // };

  // const checkHeaders = (param1, param2) => {
  //   return param1 === "mac_address" && param2 === "vehicalNo"
  //     ? true
  //     : "Headers not matching";
  // };

  // const checkData = (arr) => {
  //   const vehicalNoApi = tableData.map((item) => item.vehicalNo);
  //   const duplicateDataArr = [];

  //   for (let i = 0; i < arr.length; i++) {
  //     for (let j = 0; j < vehicalNoApi.length; j++) {
  //       if (arr[i].vehicalNo === vehicalNoApi[j]) {
  //         duplicateDataArr.push(arr[i].vehicalNo);
  //       }
  //     }
  //   }

  //   return duplicateDataArr.length === 0 ? true : "Duplicate items found";
  // };

  const upload = () => {
    document.getElementById("selectCSV").click();
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onFilterTextChange = (e) => {
    gridApi.setQuickFilter(e.target.value);
  };

  const csvTemplateVehicle = ["vehicle_no", "serial_no"];

  return (
    <>
      <div className="vehicle-mgmnt-topSection">
        <input
          type="text"
          id="vehicle-filter-box"
          placeholder="Search"
          onChange={(e) => onFilterTextChange(e)}
        />

        <div className="vehicleMgmt-container">
          <button id="vehicle-plus" onClick={upload}>
            Upload CSV
          </button>
          <input
            type="file"
            accept=".csv"
            hidden
            id="selectCSV"
            onChange={onChange}
          />
        </div>

        <TemplateBtn data={csvTemplateVehicle} />
      </div>

      <div
        className="ag-theme-alpine"
        style={{
          height: "100%",
          width: "99.9%",
          paddingLeft: "1px",
          borderLeft: "1px solid #babfc7",
          borderRight: "1px solid #babfc7",
        }}
      >
        <AgGridReact
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          rowData={tableData}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={25}
          domLayout="autoHeight"
          overlayLoadingTemplate={
            '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
          }
          overlayNoRowsTemplate={
            '<span className="ag-overlay-loading-center">No data found to display.</span>'
          }
        ></AgGridReact>
      </div>
    </>
  );
}
