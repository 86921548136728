import React from "react";
import "./Dashboard.css";
import jwt_decode from "jwt-decode";
import PadLockChart from "../Charts/PadLockChart";
import { InfoCard } from "../../InfoCards";
import * as RiIcons from "react-icons/ri";

export default function Dashboard() {
  const token = sessionStorage.getItem("token");
  const decode = jwt_decode(token);

  return (
    <>
      <div className="wrapper">
        <div className="header">
          <h1>
            {decode.email}
            <span className="header-admin-icon">
              <RiIcons.RiAdminFill />
            </span>
          </h1>
        </div>

        <div className="main">
          <div className="infocard-box-top">
            <InfoCard />
          </div>

          <div className="infocard-box-bottom">
            <PadLockChart />
          </div>
        </div>
      </div>
    </>
  );
}
