import React from "react";
import VehicleManagement from "../components/VehicleManagement/VehicleManagement";

export default function Vehicle() {
  return (
    <div className="vehicle">
      <VehicleManagement />
      {/* <h1>this is vehicle mangement page</h1> */}
    </div>
  );
}
