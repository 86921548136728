import React from "react";
import "./Footer.css";
import * as AiIcon from "react-icons/ai";

export default function Footer() {
  return (
    <>
      <div className="footer-container">
        <p>
          Powered by tag8 - 3Y Ventures LLP |{" "}
          <span dangerouslySetInnerHTML={{ __html: "&copy;" }} />{" "}
          {new Date().getFullYear()}
        </p>
      </div>
    </>
  );
}
