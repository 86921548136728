import React, { useState } from "react";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Modal from "react-modal";
import "react-datepicker/dist/react-datepicker.css";

import "./Datepicker.css";
import InfyTrans from "../../assests/White-Infinity-200px.svg";
import {
  REACT_APP_NODE_ENV,
  REACT_APP_REPORT_DEV,
  REACT_APP_REPORT_LIVE,
} from "../../api/apiRoutes";

Modal.setAppElement("#root");

// const uploadReportURL = "http://localhost:3065/api/report/getReport";

// const uploadReportURL = "/api/report/getReport";

const uploadReportURL =
  REACT_APP_NODE_ENV === "DEV" ? REACT_APP_REPORT_DEV : REACT_APP_REPORT_LIVE;

export const Datepicker = () => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [data, setData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function formatFromDate(date) {
    if (date !== "") {
      let x = new Date(date);

      x.setHours(0, 0, 0, 0);

      return x;
    }
  }

  function formatToDate(date) {
    let x = new Date(date);

    x.setHours(23, 59, 59, 0);

    return x;
  }

  const onDateSubmit = (e) => {
    e.preventDefault();

    const dateObj = {
      from: formatFromDate(selectedFromDate),
      to: formatToDate(selectedToDate),
    };

    setLoading(true);

    getTxnReportData(dateObj);
  };

  const getTxnReportData = (data) => {
    const token = sessionStorage.getItem("token");
    const decoded = jwtDecode(token);
    const reportData = {
      id: decoded.companyId,
      from: data.from,
      to: data.to,
    };

    axios({
      url: uploadReportURL,
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
      },
      data: reportData,
    })
      .then((response) => {
        const result = response.data.data.reverse();

        setData(result);
        setLoading(false);
      })
      .catch((e) => console.error(e.message));
  };

  const headers = [
    { label: "User email", key: "userId" },
    // { label: "Vehicle No.", key: "vehicalId" },
    { label: "Padlock Id", key: "lockId" },
    { label: "Txn status", key: "transationType" },
    { label: "Latitude", key: "latitude" },
    { label: "Longitude", key: "longitude" },
    // { label: "Purpose", key: "purpose" },
    // { label: "Home Location", key: "exactAddress" },
    { label: "Location", key: "location" },
    { label: "Lock Operation Time", key: "uploadtime" },
    { label: "App Sync Time", key: "appSyncTime" },
  ];

  const csvReport = {
    filename: "Transaction.csv",
    headers: headers,
    data: data,
  };

  return (
    <>
      <label>From</label>

      <DatePicker
        selected={selectedFromDate}
        onChange={(date) => setSelectedFromDate(date)}
        dateFormat="dd/MM/yyyy"
        maxDate={new Date()}
        isClearable
        className="trip-date-picker"
      />

      <label>To</label>

      <DatePicker
        selected={selectedToDate}
        onChange={(date) => setSelectedToDate(date)}
        dateFormat="dd/MM/yyyy"
        maxDate={new Date()}
        isClearable
        className="trip-date-picker"
      />

      <button
        className="onDateSubmit"
        onClick={(e) => {
          onDateSubmit(e);
          setIsOpen(true);
        }}
      >
        Export
      </button>

      <Modal
        className="datePicker-modal"
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        {loading ? (
          <div className="plzWait">
            Kindly wait fetching data...
            <img src={InfyTrans} alt="spinner" className="trans-infy" />
          </div>
        ) : (
          <>
            <p>Confirm to export Transactions to .csv file ?</p>

            <span>
              <CSVLink
                className="onDateSubmit"
                {...csvReport}
                onClick={() => setIsOpen(false)}
              >
                Download
              </CSVLink>

              <button className="cancelBtn" onClick={() => setIsOpen(false)}>
                Cancel
              </button>
            </span>
          </>
        )}
      </Modal>
    </>
  );
};
