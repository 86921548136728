// CAUTION :
//If you don't know what you're doing undo the changes that you've done.
// Also being in charge of this project you also have great powers and control the project.
//But also remember with Great powers comes Great responsibilities.

//And make sure the REACT_APP_NODE_ENV = "LIVE"

// Hint :
// change the below string to "DEV" or "LIVE"
// - DEV will allow you to run the project in development mode where all the url's will directed to localhost:3065.
// - while using LIVE the url will be changed to the actual api path

export const REACT_APP_NODE_ENV = "LIVE";

export const REACT_APP_LOGIN_DEV = "http://localhost:3070/api/login/";
export const REACT_APP_LOGIN_LIVE = "/api/login";

export const REACT_APP_TXN_DEV =
  "http://localhost:3070/api/transaction/getTransaction";
export const REACT_APP_TXN_LIVE = "/api/transaction/getTransaction";

export const REACT_APP_SEARCH_DEV =
  "http://localhost:3070/api/transaction/searchTransaction";
export const REACT_APP_SEARCH_LIVE = "/api/transaction/searchTransaction";

export const REACT_APP_TXN_REPORT_DEV =
  "http://localhost:3070/api/transaction/getTransactionReport";
export const REACT_APP_TXN_REPORT_LIVE =
  "/api/transaction/getTransactionReport";

export const REACT_APP_VEHICLE_DEV =
  "http://localhost:3070/api/lock/getVehicalLockMapping";
export const REACT_APP_VEHICLE_LIVE = "/api/lock/getVehicalLockMapping";

export const REACT_APP_VEHICLE_UPLOAD_DEV =
  "http://localhost:3070/api/lock/uploadVehicalLockMapping";
export const REACT_APP_VEHICLE_UPLOAD_LIVE =
  "/api/lock/uploadVehicalLockMapping";

export const REACT_APP_USER_DEV = "http://localhost:3070/api/getAllUsers";
export const REACT_APP_USER_LIVE = "/api/getAllUsers";

export const REACT_APP_USER_UPLOAD_DEV =
  "http://localhost:3070/api/userRegister";
export const REACT_APP_USER_UPLOAD_LIVE = "/api/userRegister";

export const REACT_APP_USER_UPDATE_DEV = "http://localhost:3070/api/updateUser";
export const REACT_APP_USER_UPDATE_LIVE = "/api/updateUser";

export const REACT_APP_PADLOCK_DEV =
  "http://localhost:3070/api/lock/getAllLocks";
export const REACT_APP_PADLOCK_LIVE = "/api/lock/getAllLocks";

export const REACT_APP_PADLOCK_UPLOAD_DEV =
  "http://localhost:3070/api/lock/uploadLocks";
export const REACT_APP_PADLOCK_UPLOAD_LIVE = "/api/lock/uploadLocks";

export const REACT_APP_REPORT_DEV =
  "http://localhost:3070/api/report/getReport";
export const REACT_APP_REPORT_LIVE = "/api/report/getReport";
