import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import Modal from "react-modal";
import jwt_decode from "jwt-decode";
import axios from "axios";
import * as XLSX from "xlsx";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import * as IoIcons from "react-icons/io";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
// import { Delete, Edit, Close } from "@mui/icons-material";

import "./LostLock.css";
import { TemplateBtn } from "../Utility/TemplateBtn";
import {
  REACT_APP_NODE_ENV,
  REACT_APP_PADLOCK_DEV,
  REACT_APP_PADLOCK_LIVE,
  REACT_APP_PADLOCK_UPLOAD_DEV,
  REACT_APP_PADLOCK_UPLOAD_LIVE,
} from "../../api/apiRoutes";

Modal.setAppElement("#root");

// when in dev env
// const baseURL = "http://localhost:3065/api/lock/getAllLocks";
// const uploadURL = "http://localhost:3065/api/lock/uploadLocks";

// when in live/build
// const baseURL = "/api/lock/getAllLocks";
// const uploadURL = "/api/lock/uploadLocks";

const baseURL =
  REACT_APP_NODE_ENV === "DEV" ? REACT_APP_PADLOCK_DEV : REACT_APP_PADLOCK_LIVE;

const uploadURL =
  REACT_APP_NODE_ENV === "DEV"
    ? REACT_APP_PADLOCK_UPLOAD_DEV
    : REACT_APP_PADLOCK_UPLOAD_LIVE;

export default function LostLock() {
  const [modifyModalIsOpen, setModifyModalIsOpen] = useState(false);
  const [modifiedEmail, setModifiedEmail] = useState("");
  const [tableData, setTableData] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [password, setPassword] = useState("");
  const [setLockIdValue, setLockId] = useState("");


  const initialValue = {
    password: "",
  };

  const [formData, setFormData] = useState(initialValue);

  // const [formData, setFormData] = useState(initialValue);
  // const initialValue = {
  //   email: "",
  //   phonecountrycode: "",
  //   contactg: "",
  //   status: "",
  //   role: "",
  // };
  const openEndDateDialog = params => {
    if (params) {
      // console.log("Lock Id", params);
      // setFormData(params);
      setLockId(params.data.lockId);
      setModifyModalIsOpen(true);
      // const endDate = moment(new Date(couponInfo.end_date));
      // setDtpEndDate(endDate);
      // // console.log("Seto",dtpEndDate);
      // // console.log("Setdb",endDate);
      // setSelectedCouponInfo(couponInfo);
      // setIsEndDateDialogOpen(true);
    } else {
      // toast.warn("Something went wrong!!! Please try after sometimes.");
    }
  };
  const handleEndDateDialogUpdateBtnClick = async () => {
    try {
      console.log(isNaN(Number(password)), "Type password");
      var passLength = password.length;
      if (isNaN(Number(password)) === true && passLength.length != 6) {
        alert("Please enter only 6 digits number.");
        setPassword("");
      }
      else {
        var token = sessionStorage.getItem("token");
        const res = await axios({
          url: `/api/lock/updatePassword`,
          method: "post",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          data: { password: password, lockId: setLockIdValue }
        });
        if (res.status == 200) {
          setModifyModalIsOpen(false);
          alert(res.data.msg);
        } else {
          // console.error("/api/coupon/editexpiry :: Exception", res); //todo: remove after testing
          // throw "Invalid response.";
          alert("Could Not Update Password");

        }
        setPassword("");
      }



    } catch (error) {
      // console.error("/api/coupon/editexpiry :: Exception", error);
      // return toast.error("Server error. Please try after sometimes.");
    }
  };
  // const handleUserModify = (olddata) => {
  //   console.log(olddata);
  //   setFormData(olddata);
  // };
  const getData = () => {
    var token = sessionStorage.getItem("token");
    var { id, companyId } = jwt_decode(token);

    const formData = {
      // id: id,
      companyId: companyId,
    };

    axios({
      url: baseURL,
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
      },
      data: formData,
    })
      .then((response) => {
        const results = response.data.data.reverse();

        setTableData(results);
      })
      .catch((e) => console.error(e.message));
  };

  useEffect(() => {
    getData();
  }, []);

  // const handleUserModify = (olddata) => {
  //   setFormData(olddata);
  // };
  const columnDefs = [
    {
      headerName: "Lock ID",
      field: "lockId",
    },
    { headerName: "MAC address", field: "mac_address" },
    {
      headerName: "Status",
      field: "action",
      cellRendererFramework: (params) => {
        const { status } = params.data;

        return (
          <p style={{ color: status == "active" ? "black" : "black" }}>
            {status.toUpperCase()}
          </p>
        );
      },
    },
    { headerName: "Condition", field: "conditioon" },
    { headerName: "State", field: "state" },
    { headerName: "Location", field: "location" },
    // { headerName: "DC Type", field: "dcType" },
    {
      headerName: "Battery Status",
      field: "batteryStatus",
      cellRendererFramework: (params) => {
        const { batteryStatus } = params.data;

        return (
          <p
            style={{
              color: batteryStatus ? "green" : "grey",
            }}
          >
            {batteryStatus ? batteryStatus : "NA"}
          </p>
        );
      },
    },
    { headerName: "Purpose", field: "purpose" },
    // { headerName: "Created By", field: "userId" },
    {
      headerName: "Created on",
      field: "createdOn",
      cellRendererFramework: (params) => {
        const date = params.data.createdOn.split(" ");

        return (
          <button className="lockCreatedBtn">
            {date[0]} <br /> {date[1]} {date[2]}
          </button>
        );
      },
    },
    {
      headerName: "Updated on",
      field: "updatedOn",
      cellRendererFramework: (params) => {
        const date = params.data.updatedOn.split(" ");

        return (
          <button className="lockUpdatedBtn">
            {date[0]} <br /> {date[1]} {date[2]}
          </button>
        );
      },
    },
    {
      headerName: "Password Update",
      field: "lockId",
      cellRendererFramework: (params) => (
        <>
          <button
            className="modal-edit btn"
            // onClick={() => {
            //   setModifyModalIsOpen(true);
            //   // console.log(params.data.lockId);
            //   handleUserModify(params.data);
            // }}
            onClick={() => openEndDateDialog(params)}
          >
            <MdIcons.MdEdit />
          </button>
        </>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    flex: 1,
  };

  const uploadLockFile = (url, file) => {
    let formData = new FormData();
    var token = sessionStorage.getItem("token");
    var decoded = jwt_decode(token);

    formData.append("csv", file);
    formData.append("id", decoded.id);
    formData.append("companyId", decoded.companyId);

    axios({
      url: url,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((response) => {
        if (response.request.statusText === "OK") {
          alert(`${file.name} uploaded successfully`);

          window.location = window.location.pathname;
        } else {
          alert("Please try again");
        }
      })
      .catch((e) => {
        console.error(e.message);

        alert(e.message);
      });
  };

  const onChange = (e) => {
    let csvFile = e.target.files[0];
    const reader = new FileReader();

    if (csvFile.size > 0) {
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });

        uploadLockFile(uploadURL, csvFile);
      };

      reader.readAsBinaryString(csvFile);
    } else {
      alert(`${csvFile.name} file size is ${csvFile.size} which is empty`);
    }
  };

  const upload = () => {
    document.getElementById("selectCSV").click();
  };

  const uploadUserUpdateData = () => {
    const modifyFormData = {
      email: modifiedEmail
    };

    // axios({
    //   url: updateUserURL,
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "Application/json",
    //   },
    //   data: modifyFormData,
    // })
    //   .then((response) => {
    //     console.info(response);
    //   })
    //   .catch((e) => console.error(e.message));
  };
  const handleModifySubmit = (e) => {
    e.preventDefault();
    uploadUserUpdateData();
  };

  const handleUpdateUserModalClose = (e) => {
    e.preventDefault();

    setModifyModalIsOpen(false);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onFilterTextChange = (e) => {
    gridApi.setQuickFilter(e.target.value);
  };

  const csvTemplateLock = ["mac_address", "serial_no"];

  return (
    <>
      <div className="lost-lock-topSection">
        <input
          type="text"
          id="lock-filter-box"
          placeholder="Search"
          onChange={(e) => onFilterTextChange(e)}
        />

        <div className="lockUpload-container">
          <button id="lock-plus" onClick={upload}>
            Upload CSV
          </button>

          <input type="file" hidden id="selectCSV" onChange={onChange} />
        </div>

        <TemplateBtn data={csvTemplateLock} />
      </div>

      <div
        className="ag-theme-alpine"
        style={{
          height: "100%",
          width: "99.9%",
          paddingLeft: "1px",
          borderLeft: "1px solid babfc7",
          borderRight: "1px solid babfc7",
        }}
      >
        <AgGridReact
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          rowData={tableData}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={25}
          domLayout="autoHeight"
          overlayLoadingTemplate={
            '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
          }
          overlayNoRowsTemplate={
            '<span className="ag-overlay-loading-center">No data found to display.</span>'
          }
        ></AgGridReact>
        <Modal
          className="modify_modal"
          isOpen={modifyModalIsOpen}
          onRequestClose={() => setModifyModalIsOpen(false)}
        >
          <form className="userFormContainer" onSubmit={handleModifySubmit}>
            {/* <form className="userFormContainer"> */}
            <h2>
              <IoIcons.IoMdAlert /> Update Lock Password
            </h2>

            <label htmlFor="email">Lock ID</label>
            <input
              className="modalInput"
              type="number"
              name="email"
              // defaultValue={params.data.lockId}
              //value={formData.email}
              value={setLockIdValue}
              onChange={(e) => setLockId(e.target.value)}
            />
            <label htmlFor="password">Lock Password</label>
            <input
              className="modalInput"
              type="password"
              name="password"
              maxLength={6}
              // defaultValue={params.data.lockId}
              // value={formData.password}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span style={{ display: "flex", justifyContent: "space-evenly" }}>
              {/* <input type="submit" value="Update" className="updateBtn" /> */}

              <button
                onClick={handleEndDateDialogUpdateBtnClick}
                className="modal-closeBtn"
              >
                Update
              </button>
              <button
                onClick={(e) => handleUpdateUserModalClose(e)}
                className="modal-closeBtn"
              >
                Cancel
              </button>
            </span>
          </form>
        </Modal>
      </div>
    </>
  );
}
