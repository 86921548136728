import React from "react";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";

export const NavDetailsDev = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <MdIcons.MdDashboard />,
    cName: "nav-text",
  },
  {
    title: "Transaction Master",
    path: "/trip",
    icon: <BiIcons.BiTrip />,
    cName: "nav-text",
  },
  // {
  //   title: "Vehicle Management",
  //   path: "/vehicle",
  //   icon: <AiIcons.AiFillCar />,
  //   cName: "nav-text",
  // },
  {
    title: "User Management",
    path: "/user",
    icon: <FaIcons.FaUserAlt />,
    cName: "nav-text",
  },
  {
    title: "Lock Master",
    path: "/lock",
    icon: <BsIcons.BsLockFill />,
    cName: "nav-text",
  },
  // },
  // {
  //   title: "Reports",
  //   path: "/reports",
  //   icon: <AiIcons.AiOutlineFileDone />,
  //   cName: "nav-text",
  // },
];

export const NavDetails = [
  {
    title: "Transaction Master",
    path: "/trip",
    icon: <BiIcons.BiTrip />,
    cName: "nav-text",
  },
  // {
  //   title: "Vehicle Management",
  //   path: "/vehicle",
  //   icon: <AiIcons.AiFillCar />,
  //   cName: "nav-text",
  // },
  {
    title: "User Management",
    path: "/user",
    icon: <FaIcons.FaUserAlt />,
    cName: "nav-text",
  },
  {
    title: "Lock Master",
    path: "/lock",
    icon: <BsIcons.BsLockFill />,
    cName: "nav-text",
  },
  // {
  //   title: "Reports",
  //   path: "/reports",
  //   icon: <AiIcons.AiOutlineFileDone />,
  //   cName: "nav-text",
  // },
];
