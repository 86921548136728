import React from "react";
import UserManagement from "../components/UserMangement/UserManagement";

export default function User() {
  return (
    <div className="user-management">
      <UserManagement />
      {/* <h1>this is user mangement page</h1> */}
    </div>
  );
}
