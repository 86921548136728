import React from "react";
import LostLock from "../components/LostLock/LostLock";

export default function Lock() {
  return (
    <>
      <LostLock />
      {/* <h1>this is lock master page</h1> */}
    </>
  );
}
