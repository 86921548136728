import React from "react";
import Navbar from "./components/Nav/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import ProtectedRoute from "./api/protected.route";
import DashboardPage from "./pages/DashboardPage";
import TripMasterPage from "./pages/TripMasterPage";
import Vehicle from "./pages/VehiclePage";
import UserPage from "./pages/UserPage";
import LockPage from "./pages/LockPage";
import LoginPage from "./pages/LoginPage.js";
import Footer from "./components/Footer/Footer";
import ReportsPage from "./pages/ReportsPage";

function App() {
  return (
    <>
      <Router>
        <Navbar />

        <Routes>
          <Route exact path="/" element={<LoginPage />} />
          {/* {sessionStorage.getItem("token") ? ( */}
          {/* <> */}
          <Route exact path="/dashboard" element={<DashboardPage />} />
          <Route exact path="/trip" element={<TripMasterPage />} />
          <Route exact path="/vehicle" element={<Vehicle />} />
          <Route exact path="/user" element={<UserPage />} />
          <Route exact path="/lock" element={<LockPage />} />
          <Route exact path="/reports" element={<ReportsPage />} />
          {/* </> */}
          {/* ) : ( */}
          {/* <Route exact path="/login" element={<LoginPage />} /> */}
          {/* )} */}
        </Routes>

        <Footer />
      </Router>
    </>
  );
}

export default App;
